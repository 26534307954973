import React from "react";
import { useGlobal } from "reactn";
import SEO from "../components/seo";
import BackgroundImage from "gatsby-background-image";
import * as Global from "../css/globalStyle";
import "../css/global.css";
import * as Styled from "../css/newsStyle";
import * as Cardlfex from "../css/CardFlexStyle";
import { useStaticQuery, graphql } from "gatsby";
import App from "./app";
import FirstThreeNews from "../components/firstThreeNews";
import getLang from "../components/scripts/getLang";
import TheWeekend from "../components/theWeekend";
import SmallTalkImage from "../images/smalltalks.png";
import SmallTalkComponent from "../components/smalltalk"; 


const Hem = props => {
  let [english] = useGlobal("English");
  let William = "http://wnordqvist.com/ ";
  let Jonathan = "http://jsilkewall.com/ ";

  console.log(
      William + Jonathan
  );

  let hero = useStaticQuery(
    graphql`
      query {
        allContentfulHem {
          edges {
            node {
              seoTitel
              seoDescription
              node_locale
              rubrik
              underRubrik
              heroLink
              rubrikPuff1
              underrubrikPuff1
              lnkPuff1
              rubrikPuff2
              underrubrikPuff2
              lnkPuff2
              rubrikPuff3
              underrubrikPuff3
              lnkPuff3
              bildPuff1 {
                fluid(quality: 100) {
                  ...GatsbyContentfulFluid
                }
              }
              bildPuff2 {
                fluid(quality: 100) {
                  ...GatsbyContentfulFluid
                }
              }
              bildPuff3 {
                fluid(quality: 100) {
                  ...GatsbyContentfulFluid
                }
              }
              heroBild {
                fluid(quality: 100) {
                  ...GatsbyContentfulFluid
                }
                file {
                  url
                }
              }
            }
          }
        }
        contentfulTheWeekend {
          node_locale
          lnkTillLogga
          link
          link2
          link3
          weekendBild {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
          weekendBild2 {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
          weekendBild3 {
            fluid(quality: 100) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    `
  );

  let heroIndex = getLang(english, hero.allContentfulHem);
  let {
    seoTitel,
    seoDescription,
    heroBild,
    rubrik,
    underRubrik,
    heroLink,
    rubrikPuff1,
    underrubrikPuff1,
    lnkPuff1,
    rubrikPuff2,
    underrubrikPuff2,
    lnkPuff2,
    rubrikPuff3,
    underrubrikPuff3,
    lnkPuff3,
    bildPuff1,
    bildPuff2,
    bildPuff3
  } = heroIndex[0].node;
  let heroVideo = "";
  
  if (heroBild.fluid === null || heroBild.fluid.src === null) {
    heroVideo = "https:" + heroBild.file.url;
  }

  return (
    <App>
      <SEO title={seoTitel} description={seoDescription} />
      {(heroBild.fluid === null || heroBild.fluid.src === null) ? (
        <a className="textDecorationNone" href={heroLink ? heroLink : null}>
          <Global.BackgroundVideo>
            <video loop autoPlay muted playsInline>
              <source src={heroVideo} type="video/mp4" />
            </video>
          </Global.BackgroundVideo>
        </a>
      ) : (
        <BackgroundImage
          Tag="section"
          className={"hero"}
          fluid={heroBild.fluid}
          backgroundColor={`#040e18`}
        >
          <a className="textDecorationNone" href={heroLink ? heroLink : null}>
            <Global.HeroContainer>
              <Global.HeroText>
                <h1> {rubrik} </h1>
                <p> {underRubrik} </p>
              </Global.HeroText>
            </Global.HeroContainer>
          </a>
        </BackgroundImage>
      )}

      <Global.Topic>
        <h2> { english ? 'Latest News' : 'Senaste Nyheter'} </h2>
        <FirstThreeNews english={english} page="index" />
      </Global.Topic>

      <Global.Topic >
        <h2> { english ? 'our recommendations' : 'Våra tips'} </h2>
      </Global.Topic>
      <Styled.RestOfNewsFlex page="index">
        <a className="textDecorationNone" href={lnkPuff1 ? lnkPuff1 : null}>
          <BackgroundImage
            Tag="div"
            className={"underNews"}
            fluid={bildPuff1.fluid}
          >
            <Cardlfex.FlexH1>
              <Cardlfex.H2>{rubrikPuff1}</Cardlfex.H2>
              <p>{underrubrikPuff1?.toUpperCase()}</p>
            </Cardlfex.FlexH1>
          </BackgroundImage>
        </a>

        <a className="textDecorationNone" href={lnkPuff2 ? lnkPuff2 : null}>
          <BackgroundImage
            Tag="div"
            className={"underNews"}
            fluid={bildPuff2.fluid}
          >
            <Cardlfex.FlexH1>
              <Cardlfex.H2>{rubrikPuff2}</Cardlfex.H2>
              <p>{underrubrikPuff2?.toUpperCase()}</p>
            </Cardlfex.FlexH1>
          </BackgroundImage>
        </a>

        <a className="textDecorationNone" href={lnkPuff3 ? lnkPuff3 : null}>
          <BackgroundImage
            Tag="div"
            className={"underNews"}
            fluid={bildPuff3.fluid}
          >
            <Cardlfex.FlexH1>
              <Cardlfex.H2>{rubrikPuff3}</Cardlfex.H2>
              <p>{underrubrikPuff3?.toUpperCase()}</p>
            </Cardlfex.FlexH1>
          </BackgroundImage>
        </a>
      </Styled.RestOfNewsFlex>

      <TheWeekend english={english} data={hero.contentfulTheWeekend} />

      <Global.CenterDiv>
        <img
          alt="Smalltalk"
          className="smallTalkImage"
          src={SmallTalkImage}
        ></img>

        {english ? 
         <p> Meet all exciting personalities who create unforgettable experiences and daily greets you.<br /> Here we tell their stories.</p>
          :<p> Möt alla spännande personligheter som skapar minnesvärda upplevelser och dagligen möter dig.<br/> Här berättar vi deras historier. </p>}
      </Global.CenterDiv>
      <SmallTalkComponent />
    </App>
  );
};

export default Hem;
