import React from "react";
import * as Styled from "../css/theWeekendStyle";
import Img from "gatsby-image";

import weekend from "../images/the-weekend.png";

import { useStaticQuery, graphql } from "gatsby";
import getLang from "../components/scripts/getLang";
import { useGlobal } from "reactn";
import * as Global from "../css/globalStyle";

const TheWeekend = ({ data }) => {
    let [english] = useGlobal("English");
    let hero = useStaticQuery(
        graphql`
          query {
            allContentfulTheWeekend {
              edges {
                node {
                  node_locale
                  link
                  weekendBild {
                    fluid(quality: 100) {
                      ...GatsbyContentfulFluid
                    }
                    file {
                      url
                      contentType
                    }
                  }
                }
              }
            }

          }
        `
      );

  let heroIndex = getLang(english, hero.allContentfulTheWeekend);

  return (
    <Styled.container>
      <Styled.WeekendHeader>
        <a href={data.lnkTillLogga} target="_blank" rel="noopener noreferrer">
          <img
            alt="the weekeed"
            src={weekend}
          />
        </a>

      </Styled.WeekendHeader>
      <Styled.ImgContainer>
        <Styled.WeekendLarge>
        {heroIndex[0].node.weekendBild.file.contentType === "video/mp4" ? (
          <a className="textDecorationNone" href={heroIndex[0].node.link}>
            <Global.BackgroundVideo>
              <video loop autoPlay muted playsInline style={{ objectFit: "fill", height: "100%", width: "100%", padding: "0px" }}>
                <source src={heroIndex[0].node.weekendBild.file.url} type="video/mp4" />
              </video>
            </Global.BackgroundVideo>
          </a>
        ) : (
          <a href={heroIndex[0].node.link} target="_blank" rel="noopener noreferrer">
            <Img
              Tag="div"
              className="LargeImage"
              objectFit="cover"
              objectPosition="50% 50%"
              fluid={heroIndex[0].node.weekendBild.fluid}
            />
              </a>
          )}
        </Styled.WeekendLarge>

        <Styled.WeekendSmall>
          <a href={data.link2} target="_blank" rel="noopener noreferrer">
            <Img
              Tag="div"
              className="SmallImage"
              objectFit="cover"
              objectPosition="50% 50%"
              fluid={data.weekendBild2.fluid}
            />
          </a>

          <a href={data.link3} target="_blank" rel="noopener noreferrer">
            <Img
              Tag="div"
              className="SmallImage"
              objectFit="cover"
              objectPosition="50% 50%"
              fluid={data.weekendBild3.fluid}
            />
          </a>
        </Styled.WeekendSmall>
      </Styled.ImgContainer>
    </Styled.container>
  );
};

export default TheWeekend;
