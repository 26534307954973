
import { graphql, useStaticQuery } from "gatsby";

export const NewsQuery = () => {
  let query = useStaticQuery(
    graphql`
    query {
      allContentfulNyheter(sort: { fields: [nyhetsDatum], order: ASC }) {
        edges {
          node {
            updatedAt(formatString: "YY-MM/DD  h:mm:ss")
            nyhetsDatum
            node_locale
            slug
            rubrik
            isEvent
            huvudBild {
              fluid(quality: 100) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    }
    
    `
  );

  return query.allContentfulNyheter;
};
