import React from "react";
import BackgroundImage from "gatsby-background-image";
import * as Styled from "../css/newsStyle";
import { Link } from "gatsby";
import getLang from "./scripts/getLang";

import { NewsQuery } from "./scripts/graphQlQuerys/newsQuery";

export const FirstThreeNews = props => {
  let topNews = getLang(props.english, NewsQuery());
  topNews = topNews.sort((a, b) => {
    return (
      (a.node.nyhetsDatum === null) - (b.node.nyhetsDatum === null) ||
      -(a.node.nyhetsDatum > b.node.nyhetsDatum) ||
      +(a.node.nyhetsDatum < b.node.nyhetsDatum)
    );
  });

  let heroNews = [...topNews.filter(n => !n.node.isEvent).slice(0, 3)];

  return (
    <Styled.FlexNewsHero className="hero" page={props.page}>
      <Link
        className="textDecorationNone"
        to={`/news/${heroNews[0].node.slug}`}
      >
        <BackgroundImage
          Tag="div"
          className={"field1"}
          fluid={heroNews[0].node.huvudBild.fluid}
          backgroundColor={`#040e18`}
        >
          <div>
            <h2><span style={{fontSize:'15px'}}>{heroNews[0].node.nyhetsDatum.replace(/-/g, '.')}</span>{heroNews[0].node.rubrik}</h2>
          </div>
        </BackgroundImage>
      </Link>

      <Styled.FlexNewsHero2>
        <Link
          className="textDecorationNone"
          to={`/news/${heroNews[1].node.slug}`}
        >
          <BackgroundImage
            Tag="div"
            className={"field2"}
            fluid={heroNews[1].node.huvudBild.fluid}
            backgroundColor={`#040e18`}
          >
            <div>
              <h2> <span style={{fontSize:'15px'}}>{heroNews[1].node.nyhetsDatum.replace(/-/g, '.')}</span>{heroNews[1].node.rubrik}</h2>
            </div>
          </BackgroundImage>
        </Link>

        <Link
          className="textDecorationNone"
          to={`/news/${heroNews[2].node.slug}`}
        >
          <BackgroundImage
            Tag="div"
            className={"field2"}
            fluid={heroNews[2].node.huvudBild.fluid}
            backgroundColor={`#040e18`}
          >
            <div className="newsText">
              <h2><span style={{fontSize:'15px'}}>{heroNews[2].node.nyhetsDatum.replace(/-/g, '.')}</span>{heroNews[2].node.rubrik}</h2>
            </div>
          </BackgroundImage>
        </Link>
      </Styled.FlexNewsHero2>
    </Styled.FlexNewsHero>
  );
};

export default FirstThreeNews;
